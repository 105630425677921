import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import SkuTile from "../../components/SkuTile"
import Image from "../../components/Image"

import styles from "./story.module.scss"

const FW21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "wild-one-90-nappa-boots",
      "wild-one-65-nappa-boots",
      "great-frontier-leather-boots",
      "idol-suede-boots",
    ].map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Pull On"
        description="Fall Winter '21"
        url={location.pathname}
      />

      <ul className={styles.grid}>
        {allSkus.map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`story-pull-on`}
          />
        ))}
        <li className={styles.storyItem31a}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_PullOn_1_e7a87633-83fb-4aa5-8307-e5f1d379f28c_1320x.jpg?v=1629750133",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_PullOn_1_e7a87633-83fb-4aa5-8307-e5f1d379f28c_850x.jpg?v=1629750133",
            }}
            altText="Pull On Story"
          />
        </li>
      </ul>
    </>
  )
}

export default FW21

export const query = graphql`
    query PullOnQuery {
        allProductStyle(filter: {handle: {in: [
            "wild-one-90-nappa-boots",
            "wild-one-65-nappa-boots",
            "great-frontier-leather-boots",
            "idol-suede-boots",
        ]}}) {
            nodes {
                ...PLPStyleFragment
            }
        }
    }
`
